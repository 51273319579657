@import url(https://fonts.googleapis.com/css2?family=Assistant&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: 'Assistant', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{box-sizing:border-box}a{text-decoration:none;color:rgba(0,0,0,.54)}.card{border-radius:10px;background-color:#fff;box-shadow:rgba(49,53,59,.15) 0px 1px 6px 0px}.p{padding:1rem}
