@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap');

$main-blue: #0049A8;
$second-blue: #015EB6;
$main-yellow: #FDD922;
$main-orange: #FD4000;
$second-orange: #FF6501;

* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: rgba(0,0,0,.54);
}
.card{
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(49, 53, 59, 0.15) 0px 1px 6px 0px;
}

.p{
  padding: 1rem;
}